import './css/loading-bar.css'
import { setGlobalConfig } from './js/config.js';


document.addEventListener('DOMContentLoaded', async () => {

    const path = window.location.pathname;
    let config;

    // Fetch configuration from the external API
    const parts = path.split('/');
    const lastPart = parts[parts.length - 1];

    // Check if lastPart is not empty, otherwise use 'default'
    const configName = lastPart !== "" ? lastPart : 'default';

    console.log(configName)
    
    try {
        // Call the /configs endpoint with the dynamic configName as a parameter
        //const apiResponse = await fetch(`C:\Users\aldebaran\Desktop\figueres\backend\poum.json`, {
        const apiResponse = await fetch(`https://aldebaran-dev-server-1.sbs/configs/${configName}`, {
            credentials: 'omit'
        });

        if (!apiResponse.ok) {
            throw new Error(`Failed to fetch config: ${apiResponse.statusText}`);
        }

        // Parse the fetched config JSON
        const config = await apiResponse.json();

        // Set the global config
        setGlobalConfig(config);
    } catch (error) {
        console.error('Error fetching config:', error);
    }


    // SPA initialization
    const scriptModules = [
        import('./js/map_def.js'),
        import('./js/search_bar.js'),
        import('./js/print.js'),
        import('./js/tool-bar.js'),
        import('./js/llegendes.js'),
        import('./js/traduccions.js'),
        import('./js/copy_coords.js'),
        import('./js/gml-download.js'),
        import('./js/screen_orientaton.js'),
        import('./js/enllacos_interes.js'),
        import('./js/page_title.js'),
        import('./js/layers_control.js')
    ];

    // Load all scripts using dynamic import
    Promise.all(scriptModules).then(() => {
        const overlay = document.querySelector('.overlay');
        if (overlay) {
            overlay.style.display = 'none';
        }
    });

});





